<script setup lang="ts">
const { size = "md" } = defineProps<{
  alt?: string;
  src: string;
  size?: "sm" | "md" | "lg";
}>();

const sizeClasses = {
  sm: "w-16 h-16 p-1",
  md: "w-16 h-16  md:h-28 md:w-28 p-1 md:p-2",
  lg: "w-32 h-32 p-4 md:p-6",
};

const sc = computed(() => sizeClasses[size]);
</script>
<template>
  <NuxtImg
    width="600"
    :src="src"
    :class="sc"
    class="bg-white rounded-full shadow dark:bg-slate-900 dark:shadow-gray-700"
    :alt="alt"
  />
</template>
